import React from "react"
import { Link } from "gatsby"
import { Navbar, Nav, Container } from "react-bootstrap"
import Logo from "../images/svg/logo.svg"
import AnchorLink from "react-anchor-link-smooth-scroll"

const CustomNavbar = ({ pageInfo, location }) => {
  return (
    <>
      <Navbar className="navbar">
        <Container>
          <Navbar.Brand as="span" className="mx-0">
            <Link to="/">
              <Logo alt="Xandor" width="130" />
            </Link>
          </Navbar.Brand>
          <Nav>
            {location === "/" && (
              <>
                <AnchorLink offset="70" className="nav-link" href="#about">
                  About
                </AnchorLink>
                <AnchorLink offset="70" className="nav-link" href="#releases">
                  Music
                </AnchorLink>
                <AnchorLink offset="70" className="nav-link" href="#press">
                  Press
                </AnchorLink>
                <AnchorLink offset="70" className="nav-link" href="#contact">
                  Contact
                </AnchorLink>
              </>
            )}
            {location !== "/" && (
              <>
                <Link className="nav-link" to="/#about">
                  About
                </Link>
                <Link className="nav-link" to="/#releases">
                  Music
                </Link>
                <Link className="nav-link" to="/#press">
                  Press
                </Link>
                <Link className="nav-link" to="/#contact">
                  Contact
                </Link>
              </>
            )}
          </Nav>
        </Container>
      </Navbar>
    </>
  )
}

export default CustomNavbar
