import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import { FaSpotify } from "@react-icons/all-files/fa/FaSpotify";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { SiApplemusic } from "@react-icons/all-files/si/SiApplemusic";
import { FaYoutubeSquare } from "@react-icons/all-files/fa/FaYoutubeSquare";
import { FaSoundcloud } from "@react-icons/all-files/fa/FaSoundcloud";

const Footer = () => {
  return (
    <footer>
      <Container className="">
        <Row>
          <Col>
            <p>Follow & Subscribe</p>
          </Col>
        </Row>
        <Row xs="auto" className="socials justify-content-center" >
          <Col xs="2" lg="1"><a type="link" aria-label="Instagram" href="https://www.instagram.com/xandor/"><FaInstagram/></a></Col>
          <Col xs="2" lg="1"><a type="link" aria-label="Spotify" href="https://bit.ly/XandorSpotify"><FaSpotify /></a></Col>
          <Col xs="2" lg="1"><a type="link" aria-label="Apple Music" href="https://music.apple.com/us/artist/xandor/1429390047"><SiApplemusic /></a></Col>
          <Col xs="2" lg="1"><a type="link" aria-label="Youtube Music" href="https://www.youtube.com/channel/UClV0OHOYmPBDeKrUxxmPcew"><FaYoutubeSquare /></a></Col>
          <Col xs="2" lg="1"><a type="link" aria-label="Soundcloud" href="https://soundcloud.com/xandormusic"><FaSoundcloud /></a></Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <span>
              Copyright © {new Date().getFullYear()}, Xandor&trade;. All rights reserved.
              {` `}
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
