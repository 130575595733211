/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container } from "react-bootstrap"

import Navbar from "./navBar"
import Footer from "./footer"

const Layout = ({ children, location }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          siteUrl,
        }
      }
    }
  `)

  return (
    <>
      <Container fluid className="px-0 main">
        <Navbar pageInfo={data.site.siteMetadata.siteUrl} location={ location?.pathname || ''}/>
        <main>{children}</main>
      </Container>
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
